import SearchIcon from '../shared/icon/SearchIcon';
import DownloadIcon from '../shared/icon/DownloadIcon';
import FunnelIcon from '../shared/icon/FunnelIcon';
import SortIcon from '../shared/icon/SortIcon';
import TableViewMenu from './TableViewMenu';
import Popover from '../shared/popover/Popover';
import NoFiltersSvg from '../../assets/images/empty-filters.svg';
import NoSortingSvg from '../../assets/images/empty-sorting.svg';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { useMemo, useState } from 'react';
import { Input, InputStyle } from '../shared/form-control/Input';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { useTranslation } from 'react-i18next';

const TableViewGlobalActions = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const { templateModuleSectionId, selectedTableView } = useTableView();
  const { t } = useTranslation(['table-view']);

  const canFilter = useMemo(() => {
    return !!selectedTableView?.columnConfigurations;
  }, [selectedTableView?.columnConfigurations]);

  const disabledCss = useMemo(() => {
    return !canFilter ? 'pointer-events-none opacity-40' : '';
  }, [canFilter]);

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const buttonClasses = 'flex items-center justify-center h-10 w-9 rounded-md bg-gray-6 text-gray-2 p-1 cursor-pointer hover:bg-gray-5';
  return (
    <div className="piped-items flex items-center gap-2">
      {templateModuleSectionId && (
        <div className="!pr-1">
          <TableViewMenu />
        </div>
      )}
      <div className={`${disabledCss} flex flex-shrink-0 items-center gap-2 !pl-4 !pr-1`}>
        <Popover
          content={
            <div className="w-128 p-4">
              <div className="flex items-center justify-between">
                <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.filters-popover.title')}</h3>
                <Button type={ButtonType.TERTIARY} size={ButtonSize.S} disabled>
                  {t('table-view:global-actions.filters-popover.reset')}
                </Button>
              </div>

              <div className="text-center">
                <img src={NoFiltersSvg} alt="No active filters" className="mx-auto p-8 " />
                <p className="text-gray-2 pt-4">{t('table-view:global-actions.filters-popover.empty')}</p>
              </div>
            </div>
          }
          placement="bottom-start"
        >
          {(popover, toggle) => (
            <div {...popover} className="flex items-center">
              <FunnelIcon className={buttonClasses} onClick={toggle} />
            </div>
          )}
        </Popover>

        <Popover
          content={
            <div className="w-128 p-4">
              <div className="flex items-center justify-between">
                <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.sort-popover.title')}</h3>
                <Button type={ButtonType.TERTIARY} size={ButtonSize.S} disabled>
                  {t('table-view:global-actions.sort-popover.reset')}
                </Button>
              </div>

              <div className="text-center">
                <img src={NoSortingSvg} alt="No active filters" className="mx-auto p-8 " />
                <p className="text-gray-2 pt-4">{t('table-view:global-actions.sort-popover.empty')}</p>
              </div>
            </div>
          }
          placement="bottom-start"
        >
          {(popover, toggle) => (
            <div {...popover} className="flex items-center">
              <SortIcon className={buttonClasses} onClick={toggle} />
            </div>
          )}
        </Popover>
      </div>
      <div className={`${disabledCss} flex flex-shrink-0 items-center gap-2 !pl-4 !pr-1`}>
        <div className="flex items-center">
          <DownloadIcon className={buttonClasses} />
        </div>
        <div className="flex items-center">
          <SearchIcon className={buttonClasses} onClick={toggleSearch} />
          <div className={`transition-width px-1  duration-300 ease-in-out ${isSearchExpanded ? 'w-64 opacity-100' : 'w-0 opacity-0'}`}>
            <Input style={InputStyle.PLAIN} placeholder="Search this view" autoFocus />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableViewGlobalActions;
