import { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import { CSS } from '@dnd-kit/utilities';
import { freezeLineId } from '../../contexts/table-view/TableViewContext';
import { useTranslation } from 'react-i18next';
import { ColumnConfig, ColumnType } from '../../models/TableView';

const SortableFreezeLine: FC = () => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: freezeLineId,
    data: { type: TableViewConfigDroppableTypes.Columns, item: { value: freezeLineId, type: ColumnType.Action } as ColumnConfig },
  });
  const { t } = useTranslation(['table-view']);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      key={freezeLineId}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className="text-dpm-14 mt-2 flex w-full cursor-grabbing items-center justify-between"
    >
      <div className="border-gray-2 mr-2 flex-grow border-t border-dashed"></div>
      <span className="whitespace-nowrap text-center">{t('table-view:column-selection.freeze-line')}</span>
      <div className="border-gray-2 ml-2 flex-grow border-t border-dashed"></div>
    </div>
  );
};
export default SortableFreezeLine;
