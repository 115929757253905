import { FC, useMemo, useState } from 'react';
import { FormConfig } from '../../models/Form';
import { EmptyFormRendererInfoValues, FormRendererInfoContext, useCreateReadonlyFormRendererInfoValues } from '../../contexts/FormRendererContext';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import LanguageUtils from '../../utils/LanguageUtils';
import SelectableActionRenderer from './SelectableActionRenderer';
import Checkbox from '../shared/form-control/Checkbox';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { ColumnConfig, ColumnType, TableView } from '../../models/TableView';
import { SearchInput } from '../shared/form-control/SearchInput';
import { InputStyle } from '../shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import ObjectUtils from '../../utils/ObjectUtils';

const SelectableActionsRenderer: FC<{ form: FormConfig }> = (props) => {
  const { form } = props;
  const renderInfo = useCreateReadonlyFormRendererInfoValues(form);
  const { selectedTableView, setSelectedTableView } = useTableView();
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation(['common']);

  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>(
    form.sections.reduce(
      (acc, section) => {
        acc[section.id] = true;
        return acc;
      },
      {} as { [key: string]: boolean },
    ),
  );

  const columnConfiguration = selectedTableView?.columnConfigurations?.[form.id] || { columns: [] };

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const handleSectionSelect = (sectionId: string, actions: { id: string }[]) => {
    const actionIds = actions.map((action) => action.id);
    const allSelected = actionIds.every((id) => columnConfiguration.columns.some((col) => col.value === id && col.type === ColumnType.Action));

    if (allSelected) {
      const updatedColumns = columnConfiguration.columns.filter((col) => !(actionIds.includes(col.value) && col.type === ColumnType.Action));
      updateTableView(updatedColumns);
    } else {
      const updatedColumns = [
        ...columnConfiguration.columns.filter((col) => !actionIds.includes(col.value) || col.type !== ColumnType.Action),
        ...actionIds.map((id) => ({ value: id, type: ColumnType.Action })),
      ];
      updateTableView(updatedColumns);
    }

    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: true,
    }));
  };

  const handleActionSelect = (actionId: string) => {
    const isSelected = columnConfiguration.columns.some((col) => col.value === actionId && col.type === ColumnType.Action);

    const updatedColumns = isSelected
      ? columnConfiguration.columns.filter((col) => !(col.value === actionId && col.type === ColumnType.Action))
      : [...columnConfiguration.columns, { value: actionId, type: ColumnType.Action }];

    updateTableView(updatedColumns);
  };

  const updateTableView = (updatedColumns: ColumnConfig[]) => {
    setSelectedTableView &&
      setSelectedTableView((prev: TableView) => ({
        ...prev,
        columnConfigurations: {
          ...prev.columnConfigurations,
          [form.id]: {
            ...prev.columnConfigurations?.[form.id],
            columns: updatedColumns,
            enabled: prev.columnConfigurations?.[form.id]?.enabled ?? true,
          },
        },
      }));
  };

  const filteredSections = useMemo(() => {
    return (
      form.sections
        .map((section) => {
          const translatedSectionTitle = LanguageUtils.getTranslation('title', section.translations || {});
          const filteredActions = section.actions.filter((action) => {
            const translatedData = LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(action));
            const searchField = translatedData?.question || translatedData?.title || ''; // Use question or title
            return searchField.toLowerCase().includes(searchQuery.toLowerCase());
          });

          const isSectionMatching = translatedSectionTitle.toLowerCase().includes(searchQuery.toLowerCase());
          if (isSectionMatching) {
            return {
              ...section,
              actions: section.actions,
            };
          }

          if (filteredActions.length > 0) {
            return {
              ...section,
              actions: filteredActions,
            };
          }

          return null;
        })
        // Type guard to filter out null sections
        .filter((section): section is NonNullable<typeof section> => section !== null)
    );
  }, [searchQuery, form]);

  return (
    <div>
      <SearchInput
        placeholder={t('common:list.filter.search')}
        style={InputStyle.MINIMAL}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        value={searchQuery}
      />
      <div className="mt-3 h-[48vh] overflow-y-auto">
        {filteredSections.map((section) => {
          const selectableActions = section.actions.filter((x) => !x.noninteractive && x.type !== 'AdHocFieldsAction');
          const isExpanded = expandedSections[section.id];
          const isSectionSelected = selectableActions.every((action) =>
            columnConfiguration.columns.some((col) => col.value === action.id && col.type === ColumnType.Action),
          );

          return (
            <FormRendererInfoContext.Provider
              key={section.id}
              value={{
                ...EmptyFormRendererInfoValues,
                ...renderInfo,
                isDisplayedInline: true,
                mode: FormRendererMode.OverviewView,
                readOnly: true,
                rootFormSection: section,
                renderMinimal: true,
              }}
            >
              {selectableActions.length > 0 && (
                <div key={section.id} className="mb-4">
                  <div className="flex items-center gap-[5px]">
                    <Checkbox value={isSectionSelected} onChange={() => handleSectionSelect(section.id, selectableActions)} />
                    <ChevronIcon
                      className="h-6 w-6 cursor-pointer"
                      type={isExpanded ? ChevronType.DOWN : ChevronType.RIGHT}
                      onClick={() => toggleSection(section.id)}
                    />
                    <Heading size={HeadingSize.H6} className="font-medium">
                      {LanguageUtils.getTranslation('title', section.translations || {})}
                    </Heading>
                  </div>
                  {isExpanded && (
                    <ul className="ml-5 mt-4">
                      {selectableActions.map((action) => (
                        <li key={action.id} className="mb-2 flex items-start gap-4">
                          <SelectableActionRenderer
                            action={action}
                            section={section}
                            selected={columnConfiguration.columns.some((col) => col.value === action.id && col.type === ColumnType.Action)}
                            onChange={() => handleActionSelect(action.id)}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </FormRendererInfoContext.Provider>
          );
        })}
      </div>
    </div>
  );
};

export default SelectableActionsRenderer;
