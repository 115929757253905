import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { mainViewId, useTableView } from '../../contexts/table-view/TableViewContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TableViewActionButtons = () => {
  const { onEditTableView, onSaveTableView, onDiscardTableView, selectedTableView } = useTableView();
  const { t } = useTranslation(['table-view']);
  const isDirty = useMemo(() => {
    // Todo: selected view dirty check
    return true;
  }, []);

  return (
    <div className="flex items-center gap-2">
      {selectedTableView?.id !== mainViewId && (
        <>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onEditTableView}>
            {t('buttons.edit')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onDiscardTableView} disabled={!isDirty}>
            {t('buttons.discard')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onSaveTableView} disabled={!isDirty}>
            {t('buttons.save')}
          </Button>
        </>
      )}
    </div>
  );
};

export default TableViewActionButtons;
