import { FC } from 'react';
import { FormConfig } from '../../models/Form';
import LanguageUtils from '../../utils/LanguageUtils';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedTemplate: FormConfig;
  onClearAll: () => void;
  onSelectAll: () => void;
};

const SelectedTemplateHeader: FC<Props> = (props) => {
  const { selectedTemplate, onClearAll, onSelectAll } = props;
  const { t } = useTranslation(['table-view']);

  return (
    <div className="my-4">
      <div className="flex items-center justify-between gap-4">
        <Tooltip text={LanguageUtils.getTranslation('title', selectedTemplate?.translations || {})} truncatedTextMode>
          {(tooltip) => (
            <div {...tooltip} className="min-w-0 max-w-full truncate">
              <Heading size={HeadingSize.H6} className="min-w-0 max-w-full truncate font-medium">
                {LanguageUtils.getTranslation('title', selectedTemplate?.translations || {})}
              </Heading>
            </div>
          )}
        </Tooltip>

        <div className="flex items-center gap-4">
          <Button type={ButtonType.TERTIARY} className="!min-w-0 !px-0" size={ButtonSize.S} onClick={onSelectAll}>
            {t('table-view:column-selection.select-all')}
          </Button>
          <Button type={ButtonType.TERTIARY} className="!min-w-0 !px-0" size={ButtonSize.S} onClick={onClearAll}>
            {t('table-view:column-selection.clear-all')}
          </Button>
        </div>
      </div>
      <div className="text-dpm-14">{t('table-view:column-selection.description')}</div>
    </div>
  );
};

export default SelectedTemplateHeader;
