/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { freezeLineId, useTableView } from '../../contexts/table-view/TableViewContext';
import InlineEditor from '../shared/form-control/InlineEditor';
import ViewIcon from '../shared/icon/ViewIcon';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { FormConfig } from '../../models/Form';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import LanguageUtils from '../../utils/LanguageUtils';
import TemplateFormService from '../../services/TemplateFormService';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import SelectableActionsRenderer from './SelectableActionsRenderer';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import Tooltip from '../shared/Tooltip';
import ObjectUtils from '../../utils/ObjectUtils';
import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { ColumnConfig, ColumnType, TableView, TableViewColumnConfiguration, tableViewMetadata, TableViewMetaDataKey } from '../../models/TableView';
import SortableColumnConfig from './SortableColumnConfig';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableFreezeLine from './SortableFreezeLine';
import { useTranslation } from 'react-i18next';
import SelectedTemplateHeader from './SelectedTemplateHeader';
import SelectableMetaDataRenderer from './SelectableMetaDataRenderer';
import { Heading, HeadingSize } from '../shared/text/Heading';

export enum TableViewConfigDroppableTypes {
  Columns = 'columns',
  Templates = 'templates',
  Actions = 'actions',
  Meta = 'meta',
}

export type TableViewConfigDraggableItem = {
  type: TableViewConfigDroppableTypes;
  item: ColumnConfig;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onApplyChanges: () => void;
};

const TableViewConfigModal: FC<Props> = (props) => {
  const { open, onClose, onApplyChanges } = props;
  const { clientModuleId, templateModuleSectionId, selectedTableView, setSelectedTableView } = useTableView();
  const [templates, setTemplates] = useState<FormConfig[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<FormConfig>();
  const currentClient = useRecoilValue(currentClientAtom);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [activeDraggable, setActiveDraggable] = useState<{ actionId: string; label: string } | null>(null);
  const { t } = useTranslation(['table-view']);
  const [overIndex, setOverIndex] = useState<number | null>(null);

  const updateTableView = useCallback(
    (updatedColumns: ColumnConfig[]) => {
      if (selectedTemplate && setSelectedTableView) {
        setSelectedTableView((prev: TableView) => {
          return {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [selectedTemplate.id]: {
                ...prev.columnConfigurations?.[selectedTemplate.id],
                templateId: selectedTemplate.id,
                columns: updatedColumns,
                enabled: prev.columnConfigurations?.[selectedTemplate.id]?.enabled ?? false,
              },
            },
          };
        });
      }
    },
    [selectedTemplate, setSelectedTableView],
  );

  const onEnableTemplateChange = useCallback(
    (isEnabled: boolean, templateId: string) => {
      if (setSelectedTableView) {
        setSelectedTableView((prev: TableView) => {
          return {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [templateId]: {
                ...prev.columnConfigurations?.[templateId],
                columns: prev.columnConfigurations?.[templateId]?.columns || [],
                enabled: isEnabled,
              },
            },
          };
        });
      }
    },
    [setSelectedTableView],
  );

  const onViewNameChange = useCallback(
    (name: string) => {
      if (setSelectedTableView) {
        setSelectedTableView((prev: TableView) => {
          return {
            ...prev,
            name: name,
          };
        });
      }
    },
    [setSelectedTableView],
  );

  const updateFreezeStatus = (columns: ColumnConfig[]) => {
    const targetIndex = columns.findIndex((column) => column.value === freezeLineId);
    return columns.map((column, index) => {
      if (index < targetIndex) {
        return { ...column, freeze: true };
      } else {
        return { ...column, freeze: false };
      }
    });
  };

  const selectedColumnConfiguration: TableViewColumnConfiguration = useMemo(
    () =>
      selectedTemplate
        ? selectedTableView?.columnConfigurations?.[selectedTemplate.id] || { columns: [], enabled: false }
        : { columns: [], enabled: false },
    [selectedTableView?.columnConfigurations, selectedTemplate],
  );

  const selectTemplate = useCallback((template: FormConfig) => {
    setTemplateLoading(true);
    setSelectedTemplateId(template.id);
    TemplateFormService.getFormTemplate(template.id)
      .then((res) => {
        setSelectedTemplate(res.data);
      })
      .finally(() => {
        setTemplateLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!selectedColumnConfiguration.columns.some((col) => col.value === freezeLineId)) {
      updateTableView([...selectedColumnConfiguration.columns, { value: freezeLineId, type: ColumnType.Action }]);
    }
  }, [selectedColumnConfiguration.columns, updateTableView, selectedTemplate]);

  useEffect(() => {
    if (currentClient && open) {
      setTemplatesLoading(true);
      new ClientTemplateModuleService(currentClient.id)
        .getAllTemplateForms({ clientModuleId })
        .then((res) => {
          const sectionTemplates = res.data.filter((template) => template.templateModuleSectionId === templateModuleSectionId);
          setTemplates(sectionTemplates);
          const defaultSelectedTemplate = sectionTemplates[0];
          if (defaultSelectedTemplate) {
            selectTemplate(defaultSelectedTemplate);
          }
        })
        .finally(() => {
          setTemplatesLoading(false);
        });
    }
  }, [currentClient, templateModuleSectionId, open, clientModuleId, selectTemplate]);

  const formActions = useMemo(
    () => selectedTemplate?.sections.flatMap((section) => section.actions.filter((x) => !x.noninteractive && x.type !== 'AdHocFieldsAction')),
    [selectedTemplate?.sections],
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const data = active.data.current as TableViewConfigDraggableItem;
    if (data.type === TableViewConfigDroppableTypes.Meta) {
      setActiveDraggable({ actionId: data.item.value, label: t(`meta.${data.item.value as TableViewMetaDataKey}`) });
    } else if (data.type === TableViewConfigDroppableTypes.Actions) {
      const actionId = data.item.value;
      const draggableAction = formActions?.find((action) => action.id === actionId);
      const translatedData = draggableAction ? LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(draggableAction)) : {};
      if (draggableAction) {
        setActiveDraggable({ actionId: draggableAction.id, label: translatedData?.question || translatedData?.title });
      }
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { over, active } = event;
    if (!over || !active) {
      setOverIndex(null);
      return;
    }
    const activeData = active.data.current as TableViewConfigDraggableItem;
    if (activeData.type === TableViewConfigDroppableTypes.Actions || activeData.type === TableViewConfigDroppableTypes.Meta) {
      const overData = over.data.current as TableViewConfigDraggableItem;
      const overIndex = selectedColumnConfiguration.columns.findIndex((column) => {
        return column.value === overData.item.value;
      });
      setOverIndex(overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || !active) return;

    const activeData = active.data.current as TableViewConfigDraggableItem;
    const overData = over.data.current as TableViewConfigDraggableItem;
    setActiveDraggable(null);

    if (overData.type === TableViewConfigDroppableTypes.Columns) {
      const columnIds = selectedColumnConfiguration.columns.map((x) => x.value);
      const shouldAddToColumns = !columnIds.includes(activeData.item.value);
      if (shouldAddToColumns) {
        addColumn(activeData.item);
      } else if (active.id !== over.id) {
        moveColumn(activeData, overData);
      }
    }
  };

  const addColumn = (columnConfig: ColumnConfig) => {
    const updatedColumns = [
      ...selectedColumnConfiguration.columns.slice(0, overIndex && overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length),
      columnConfig,
      ...selectedColumnConfiguration.columns.slice(overIndex && overIndex !== -1 ? overIndex : selectedColumnConfiguration.columns.length),
    ];
    setOverIndex(null);
    updateTableView(updateFreezeStatus(updatedColumns));
  };

  const moveColumn = (activeData: TableViewConfigDraggableItem, overData: TableViewConfigDraggableItem) => {
    const oldIndex = selectedColumnConfiguration.columns.findIndex((col) => {
      return col.value === activeData.item.value;
    });

    const newIndex = selectedColumnConfiguration.columns.findIndex((col) => {
      return col.value === overData.item.value;
    });

    if (oldIndex !== newIndex) {
      const updatedColumns = arrayMove(selectedColumnConfiguration.columns, oldIndex, newIndex);
      updateTableView(updateFreezeStatus(updatedColumns));
    }
  };

  const selectAllActions = useCallback(() => {
    const actionIds = formActions?.map((action) => action.id) || [];
    const existingColumns = selectedColumnConfiguration.columns.filter((col) => actionIds.includes(col.value) && col.type === ColumnType.Action);
    const newColumns = actionIds
      .filter((actionId) => !existingColumns.some((col) => col.value === actionId && col.type === ColumnType.Action))
      .map((actionId) => ({ value: actionId, type: ColumnType.Action }));
    const updatedColumns = [...selectedColumnConfiguration.columns, ...newColumns];
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, formActions, updateTableView]);

  const clearAllActions = useCallback(() => {
    const updatedColumns = selectedColumnConfiguration.columns.filter((col) => col.type !== ColumnType.Action);
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  const selectAllMetaData = useCallback(() => {
    const metaData = Object.values(tableViewMetadata);
    const existingColumns = selectedColumnConfiguration.columns.filter(
      (col) => metaData.includes(col.value as TableViewMetaDataKey) && col.type === ColumnType.MetaData,
    );
    const newColumns = metaData
      .filter((key) => !existingColumns.some((col) => col.value === key && col.type === ColumnType.MetaData))
      .map((metaDataFieldName) => ({ value: metaDataFieldName, type: ColumnType.MetaData }));
    const updatedColumns = [...selectedColumnConfiguration.columns, ...newColumns];
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  const clearAllMetaData = useCallback(() => {
    const updatedColumns = selectedColumnConfiguration.columns.filter((col) => col.type !== ColumnType.MetaData);
    updateTableView(updatedColumns);
  }, [selectedColumnConfiguration.columns, updateTableView]);

  return (
    <ModalContext.Provider value={{ open, onClose, modalWidth: 'w-full sm:w-11/12 md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-9/12' }}>
      <StandardModal
        title={
          <InlineEditor
            prefix={<ViewIcon className="mb-1 mr-2 h-6 w-6 " />}
            className="text-dpm-20"
            inputClassName="!p-0"
            value={selectedTableView?.name || ''}
            onChange={onViewNameChange}
          />
        }
        confirmDisabled={false}
        confirmButtonTitle={t('buttons.apply')}
        onConfirmClick={onApplyChanges}
        onCancelClick={onClose}
        bare
      >
        <div className="bg-background-1 flex h-full w-full gap-2 px-4 ">
          <div className="h-[70vh] w-1/5 overflow-y-auto pt-4">
            <Heading size={HeadingSize.H6} className="mb-2 font-medium">
              {t('templates.title')}
            </Heading>
            <SkeletonLoader ready={!templatesLoading} type="listBlockRow" rows={10} size="small">
              <ul>
                {templates.map((template) => {
                  const templateTitle = LanguageUtils.getTranslation('title', template.translations || {});
                  return (
                    <li
                      key={template.id}
                      className={`${selectedTemplateId === template.id && 'bg-accent-light-mid'} hover:bg-accent-light-mid group cursor-pointer p-2`}
                      {...mouseAndKeyboardCallbackProps(() => selectTemplate(template))}
                    >
                      <div className="flex w-full items-center justify-between">
                        <div className="flex min-w-0 flex-1 items-center">
                          <DragHandleIcon className="mr-1 h-4 w-4 flex-shrink-0 opacity-0 group-hover:opacity-100" />
                          <Tooltip text={templateTitle} truncatedTextMode>
                            {(tooltip) => (
                              <div {...tooltip} className="min-w-0 max-w-full truncate">
                                {templateTitle}
                              </div>
                            )}
                          </Tooltip>
                        </div>
                        <div className="ml-auto">
                          <Checkbox
                            slider
                            sliderSize={SliderSize.S}
                            value={selectedTableView?.columnConfigurations?.[template.id]?.enabled ?? false}
                            onChange={(value) => onEnableTemplateChange(value, template.id)}
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </SkeletonLoader>
          </div>
          <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
            <DragOverlay>
              {activeDraggable ? (
                <div className="text-dpm-14 flex min-h-[16px] w-80 cursor-grabbing items-center justify-center rounded-xl border-2 bg-white bg-opacity-90 p-2 shadow-lg">
                  {activeDraggable.label}
                </div>
              ) : null}
            </DragOverlay>

            <div className="w-1/2 px-2 pt-4">
              <TabStrip borderless contentClassName="px-4 bg-white h-full" tabBgColor="bg-white">
                <TabStrip.TabHeader id="template-data" text={t('tabs.template-data')} value={null} data-cy="template-tab" />
                <TabStrip.TabHeader id="meta-data" text={t('tabs.meta-data')} value={null} data-cy="meta-data-tab" />
                <TabStrip.TabContent forId="template-data" data-cy="template-data-tab-content">
                  <SkeletonLoader ready={!templateLoading && !templatesLoading} type="listBlockRow" rows={9} size="small">
                    {selectedTemplate && (
                      <SelectedTemplateHeader selectedTemplate={selectedTemplate} onSelectAll={selectAllActions} onClearAll={clearAllActions} />
                    )}
                    {selectedTemplate && <SelectableActionsRenderer form={selectedTemplate} />}
                  </SkeletonLoader>
                </TabStrip.TabContent>
                <TabStrip.TabContent forId="meta-data" data-cy="meta-data-tab-content">
                  <SkeletonLoader ready={!templateLoading && !templatesLoading} type="listBlockRow" rows={9} size="small">
                    {selectedTemplate && (
                      <SelectedTemplateHeader selectedTemplate={selectedTemplate} onSelectAll={selectAllMetaData} onClearAll={clearAllMetaData} />
                    )}
                    {selectedTemplate && <SelectableMetaDataRenderer form={selectedTemplate} />}
                  </SkeletonLoader>
                </TabStrip.TabContent>
              </TabStrip>
            </div>

            <div className="bg-background-1 w-[30%] rounded-md px-2 pt-4">
              <Heading size={HeadingSize.H6} className="mb-2 font-medium">
                {t('selected-columns.title')}
              </Heading>
              <div className={`h-[65vh] overflow-y-auto`}>
                <div className="mt-4">
                  <div className="text-dpm-14 rounded-md bg-white p-2 font-medium">{t('meta.subTitle')}</div>
                </div>

                <div>
                  {selectedColumnConfiguration.columns.length > 0 && (
                    <SortableContext
                      items={[...selectedColumnConfiguration.columns.map((column) => column.value)]}
                      strategy={verticalListSortingStrategy}
                    >
                      {selectedColumnConfiguration.columns.map((column, index) => {
                        let title = '';
                        if (column.type === ColumnType.Action) {
                          const action = formActions?.find((action) => action.id === column.value);
                          const translatedData = action ? LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(action)) : {};
                          title = translatedData?.question || translatedData?.title;
                        } else {
                          title = t(`meta.${column.value as TableViewMetaDataKey}`);
                        }

                        return (
                          <div key={index}>
                            {column.value === freezeLineId && <SortableFreezeLine />}
                            {overIndex === index && activeDraggable?.actionId && <div className="bg-gray-6 my-1 mb-2 h-9 rounded-lg p-4" />}
                            {column.value !== freezeLineId && <SortableColumnConfig config={column} title={title} />}
                          </div>
                        );
                      })}
                      {overIndex === selectedColumnConfiguration.columns.length && activeDraggable?.actionId && (
                        <div className="bg-gray-6 my-1 mb-2 h-9 rounded-lg p-4" />
                      )}
                    </SortableContext>
                  )}
                </div>
              </div>
            </div>
          </DndContext>
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default TableViewConfigModal;
