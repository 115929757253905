import { FC } from 'react';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import Tooltip from '../shared/Tooltip';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ColumnConfig, ColumnType } from '../../models/TableView';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import Badge from '../shared/badge/Badge';

type Props = {
  config: ColumnConfig;
  title: string;
};

const SortableColumnConfig: FC<Props> = (props) => {
  const { config, title } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: config.value,
    data: { type: TableViewConfigDroppableTypes.Columns, item: config },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      key={`${config.value}`}
      style={style}
      {...listeners}
      {...attributes}
      className="text-dpm-14 group my-2 flex cursor-grabbing items-center rounded-md bg-white p-2 font-medium "
    >
      <div>
        <DragHandleIcon className="mr-1 hidden h-4 w-4 flex-shrink-0 group-hover:block" />
      </div>
      <Tooltip text={title} truncatedTextMode>
        {(tooltip) => (
          <div {...tooltip} className="min-w-0 max-w-full truncate">
            {config.type === ColumnType.MetaData && <Badge text="Meta" textClass="text-gray-2" textSize="text-dpm-12" className="mr-2" />}
            {title}
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default SortableColumnConfig;
