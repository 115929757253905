import { Trans, useTranslation } from 'react-i18next';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { FCWithChildren } from '../../types/FCWithChildren';
import TableViewActionButtons from './TableViewActionButtons';
import TableViewGlobalActions from './TableViewGlobalActions';

const TableViewTopContainer: FCWithChildren = (props) => {
  const { children } = props;
  const { clientModuleSectionName } = useTableView();
  const { t } = useTranslation(['table-view']);
  return (
    <>
      <div className="flex items-center justify-between py-2 pr-4">
        <TableViewGlobalActions />
        <div>{children}</div>
      </div>
      <div className="flex items-center justify-between px-4 py-2">
        <div>
          <Trans
            t={t}
            i18nKey="table-view:data-table.result-count"
            components={{ Bold: <span className="font-medium" /> }}
            values={{
              section: clientModuleSectionName,
              count: 0,
              total: 0,
            }}
          />
        </div>
        <TableViewActionButtons />
      </div>
    </>
  );
};

export default TableViewTopContainer;
