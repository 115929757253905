import { useCallback, useEffect, useMemo, useState } from 'react';
import TableViewTopContainer from './TableViewTopContainer';
import DataTable from './DataTable';
import EmptyView from './EmptyView';
import { freezeLineId, mainViewId, newViewId, TableViewContext, TableViewContextType } from '../../contexts/table-view/TableViewContext';
import { ColumnType, TableView, TableViewColumnConfiguration, TableViewColumnConfigurationResponse } from '../../models/TableView';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import TableViewConfigModal from './TableViewConfigModal';
import { FCWithChildren } from '../../types/FCWithChildren';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import TableViewService from '../../services/TableViewService';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { ToastType, useToasts } from '../../contexts/ToastContext';

type Props = {
  clientModuleId: string;
  clientModuleSectionId: string | null | undefined;
  templateModuleSectionId: string | null;
  sectionName: string;
};

const TableViewContent: FCWithChildren<Props> = (props) => {
  const { templateModuleSectionId, clientModuleId, sectionName, children, clientModuleSectionId } = props;
  const { t } = useTranslation(['table-view']);
  const client = useRecoilValue(currentClientAtom);
  const mainView = useMemo<TableView>(
    () => ({
      id: mainViewId,
      templateModuleSectionId,
      clientId: client?.id || '',
      name: t('views.main-view'),
      columnConfigurations: null,
      isDefault: true,
      isFavourite: false,
      isOrganisationDefault: false,
      isPersonalDefault: false,
    }),
    [client?.id, templateModuleSectionId, t],
  );

  const [selectedTableView, setSelectedTableView] = useState<TableView>(mainView);
  const [availableViews, setAvailableViews] = useState<TableView[]>([]);
  const isNewUnsavedView = useMemo(() => selectedTableView.id === newViewId, [selectedTableView]);
  const [showDiscardConfim, setShowDiscardConfim] = useState(false);
  const [showTableViewConfigModal, setShowTableViewConfigModal] = useState(false);
  const currentUser = useRecoilValue(currentUserAtom);
  const toaster = useToasts();

  const addFreezeColumn = (config: TableViewColumnConfigurationResponse) => {
    // Find the index of the last column with freeze = true
    const lastFreezeIndex = config.columns.reduce((lastIndex, column, index) => {
      return column.freeze ? index : lastIndex;
    }, -1);

    // Insert the new column after the last freeze column
    return [
      ...config.columns.slice(0, lastFreezeIndex + 1),
      { value: freezeLineId, freeze: true, type: ColumnType.Action },
      ...config.columns.slice(lastFreezeIndex + 1),
    ];
  };

  useEffect(() => {
    if (templateModuleSectionId && client) {
      if (clientModuleSectionId) {
        TableViewService.getTableViews(client.id, clientModuleSectionId).then((response) => {
          const updatedTableViews = response.data.map((tableView) => ({
            ...tableView,
            templateModuleSectionId: templateModuleSectionId,
            columnConfigurations: tableView.columnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
              acc[config.templateFormId] = {
                ...config,
                columns: addFreezeColumn(config),
              };
              return acc;
            }, {}),
          }));

          const defaultUserView = updatedTableViews.find((view) => view.isDefault && !view.isOrganisationDefault);
          const defaultView = defaultUserView ?? updatedTableViews.find((view) => view.isDefault) ?? mainView;

          mainView.isDefault = !updatedTableViews.some((view) => view.isDefault);

          setSelectedTableView(mainView.isDefault ? mainView : defaultView);
          setAvailableViews([mainView, ...updatedTableViews]);
        });
      } else {
        setAvailableViews([mainView]);
        setSelectedTableView(mainView);
      }
    }
  }, [templateModuleSectionId, mainView, client, clientModuleSectionId]);

  const onEditTableView = useCallback(() => {
    setShowTableViewConfigModal(true);
  }, []);

  const onDiscardTableView = useCallback(() => {
    setShowDiscardConfim(true);
  }, []);

  const doDiscard = useCallback(() => {
    if (isNewUnsavedView) {
      setSelectedTableView(mainView);
      setShowDiscardConfim(false);
    }
  }, [isNewUnsavedView, mainView]);

  const onSaveTableView = useCallback(() => {
    if (isNewUnsavedView) {
      TableViewService.createTableView({
        clientModuleId: clientModuleId,
        templateModuleSectionId: selectedTableView.templateModuleSectionId,
        clientId: selectedTableView.clientId,
        name: selectedTableView.name,
        userId: currentUser?.id,
        columnConfigurations: selectedTableView.columnConfigurations
          ? Object.entries(selectedTableView.columnConfigurations).map(([templateFormId, config]) => ({
              templateFormId,
              ...config,
              columns: config.columns.filter(({ value }) => value !== freezeLineId),
            }))
          : [],
      }).then((response) => {
        const newId = response.data.id;
        const updatedColumnConfigurations = response.data.columnConfigurations;
        const updatedTableView = {
          ...selectedTableView,
          id: newId,
          columnConfigurations: updatedColumnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
            acc[config.templateFormId] = { ...config, columns: addFreezeColumn(config) };
            return acc;
          }, {}),
        };
        setSelectedTableView(updatedTableView);
        setAvailableViews((prev) => [...prev, updatedTableView]);
        toaster.addToast({
          title: t('toasters.view.save.success.title'),
          description: t('toasters.view.save.success.description', { name: updatedTableView.name }),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
      });
    } else {
      TableViewService.updateTableView(selectedTableView.id, {
        name: selectedTableView.name,
        userId: currentUser?.id,
        columnConfigurations: selectedTableView.columnConfigurations
          ? Object.entries(selectedTableView.columnConfigurations).map(([templateFormId, config]) => ({
              templateFormId: templateFormId,
              ...config,
              columns: config.columns.filter(({ value }) => value !== freezeLineId),
            }))
          : [],
      }).then((response) => {
        const updatedColumnConfigurations = response.data.columnConfigurations;
        const updatedTableView = {
          ...selectedTableView,
          columnConfigurations: updatedColumnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
            acc[config.templateFormId] = { ...config, columns: addFreezeColumn(config) };
            return acc;
          }, {}),
        };
        setSelectedTableView(updatedTableView);
        setAvailableViews((prev) => prev.map((view) => (view.id === updatedTableView.id ? updatedTableView : view)));
        toaster.addToast({
          title: t('toasters.view.save.success.title'),
          description: t('toasters.view.save.success.description', { name: updatedTableView.name }),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
      });
    }
  }, [clientModuleId, currentUser?.id, isNewUnsavedView, selectedTableView, t, toaster]);

  const contextValues = useMemo<TableViewContextType>(
    () => ({
      clientModuleId: clientModuleId,
      clientModuleSectionId: clientModuleSectionId,
      templateModuleSectionId: templateModuleSectionId,
      clientModuleSectionName: sectionName,
      availableViews: availableViews,
      setAvailableViews: setAvailableViews,
      selectedTableView: selectedTableView,
      setSelectedTableView: setSelectedTableView,
      onEditTableView: onEditTableView,
      onDiscardTableView: onDiscardTableView,
      onSaveTableView: onSaveTableView,
    }),
    [
      clientModuleId,
      clientModuleSectionId,
      templateModuleSectionId,
      sectionName,
      availableViews,
      selectedTableView,
      onEditTableView,
      onDiscardTableView,
      onSaveTableView,
    ],
  );

  useEffect(() => {
    setAvailableViews([mainView]);
  }, [mainView]);

  return (
    <TableViewContext.Provider value={contextValues}>
      <div className="flex h-screen flex-col">
        <TableViewTopContainer>{children}</TableViewTopContainer>
        <div className="flex-grow border px-4">
          <div className="flex h-full flex-col items-center">
            {!isNewUnsavedView && <DataTable />}
            {isNewUnsavedView && <EmptyView />}
          </div>
        </div>
      </div>
      <ModalContext.Provider value={{ open: showDiscardConfim, onClose: () => setShowDiscardConfim(false), modalWidth: 'w-2/5' }}>
        <ConfirmationModal
          title={t('discard-changes.title')}
          description={t('discard-changes.description')}
          confirmText={t('discard-changes.confirm')}
          onConfirm={doDiscard}
          onCancel={() => setShowDiscardConfim(false)}
        />
      </ModalContext.Provider>
      <TableViewConfigModal
        open={showTableViewConfigModal}
        onClose={() => setShowTableViewConfigModal(false)}
        onApplyChanges={() => setShowTableViewConfigModal(false)}
      />
    </TableViewContext.Provider>
  );
};

export default TableViewContent;
