import { createContext, useContext } from 'react';
import { TableView } from '../../models/TableView';

export const mainViewId = 'MainView';
export const newViewId = 'NewView';
export const freezeLineId = 'FreezeLine';

export type TableViewContextType = {
  templateModuleSectionId: string | null;
  clientModuleSectionId: string | null | undefined;
  clientModuleSectionName: string;
  clientModuleId: string;
  availableViews: TableView[];
  setAvailableViews?: (value: TableView[] | ((prev: TableView[]) => TableView[])) => void;
  selectedTableView: TableView | null;
  setSelectedTableView?: (value: TableView | ((prev: TableView) => TableView)) => void;
  onEditTableView: () => void;
  onDiscardTableView: () => void;
  onSaveTableView: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const TableViewContext = createContext<TableViewContextType>(null!);
export const useTableView = (): TableViewContextType => useContext(TableViewContext);
