export type TableViewCreateRequest = {
  clientModuleId: string;
  templateModuleSectionId: string | null;
  name: string;
  clientId: string;
  userId?: string;
  columnConfigurations: TableViewColumnConfigurationRequest[];
};

export type TableViewUpdateRequest = {
  name: string;
  userId?: string;
  columnConfigurations: TableViewColumnConfigurationRequest[];
};

export type TableViewColumnConfigurationRequest = { templateFormId: string } & TableViewColumnConfiguration;
export type TableViewColumnConfigurationResponse = { templateFormId: string } & TableViewColumnConfiguration;

export type TableView = {
  id: string;
  templateModuleSectionId: string | null;
  name: string;
  clientId: string;
  userId?: string;
  isDefault: boolean;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  tableViewDefaultId?: string;
  isFavourite: boolean;
  columnConfigurations: Record<string, TableViewColumnConfiguration> | null;
};

export type TableViewResponse = {
  id: string;
  clientModuleSectionId: string;
  name: string;
  clientId: string;
  userId?: string;
  isDefault: boolean;
  isPersonalDefault: boolean;
  isOrganisationDefault: boolean;
  tableViewDefaultId?: string;
  isFavourite: boolean;
  columnConfigurations: TableViewColumnConfigurationResponse[];
};

export type TableViewColumnConfiguration = {
  id?: string;
  enabled: boolean;
  columns: ColumnConfig[];
  filters?: ColumnFilter[];
  sorting?: ColumnSort[];
};

export type CreateTableViewDefaultRequest = {
  tableViewId: string;
  clientModuleSectionId: string;
  clientId: string;
  userId?: string;
};

export type TableViewDefaultResponse = {
  id: string;
};

export enum TableViewType {
  Organisation = 0,
  User = 1,
}

export type ColumnFilter = {
  key: string;
  value: string;
};

export type ColumnSort = {
  actionId: string;
  direction: SortDirection;
};

export enum SortDirection {
  Ascending = 0,
  Descending = 1,
}

export enum ColumnType {
  Action = 0,
  MetaData = 1,
}

export type ColumnConfig = {
  value: string;
  type: ColumnType;
  freeze?: boolean;
};

export const tableViewMetadata = {
  dueDate: 'dueDate',
  members: 'members',
  status: 'status',
  lastModified: 'lastModified',
  createdBy: 'createdBy',
  createdOn: 'createdOn',
  effectiveDate: 'effectiveDate',
  progress: 'progress',
  risk: 'risk',
  allReferences: 'allReferences',
  allAttachments: 'allAttachments',
  owner: 'owner',
  editor: 'editor',
  viewer: 'viewer',
  approver: 'approver',
  reviewer: 'reviewer',
} as const;

export type TableViewMetaDataKey = keyof typeof tableViewMetadata;
